import React from 'react'

export default function ComingSoon(props) {
	return (
		<section className="flex flex-row items-start flex-wrap pt-12 pb-16 bg-boswell-handwriting">
			<div className={`p-4 md:p-12 w-full`}>
				<h2 className={`text-4xl text-center`}>Coming Soon!</h2>
			</div>
		</section>
	)
}
