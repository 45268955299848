import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BoxOffice from '../../components/Page/BoxOffice'
import { showEvents } from '../../utils/dates'
import ComingSoon from '../../components/Page/ComingSoon'

const boxOffice = ({location, data}) => {

	const { env } = data.site.siteMetadata.config

	return (
		<Layout>
			{/*<BannerImage image={data.file.childImageSharp.fluid} />*/}
			{showEvents() || env !== 'production' ? <BoxOffice festivalYear={2024} pageLocation={location} pageType={`main`}/> : <ComingSoon />}
		</Layout>
	)
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-006.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
  site {
		siteMetadata {
			config {
				env
			}
		}
	}
}
`

export default boxOffice
